import React from "react"
import tw from "twin.macro"
import { Heading, SubHeading, SubSubHeading } from "./primitives/panelHeading"

const Container = tw.div`
flex flex-col md:mx-auto text-center items-center mb-12 md:mb-24 prose prose-sm sm:prose lg:prose-lg xl:prose-xl`

const CTA = tw.button`
mx-auto mt-6 hover:underline hover:bg-purple-800 bg-primary text-white cursor-pointer font-bold rounded-full py-4 px-8 shadow-lg
`

const Panel = ({
  heading,
  subHeading,
  subSubHeading,
  ctaLink,
  ctaText,
  image,
}) => {
  return (
    <Container>
      {heading ? <Heading>{heading}</Heading> : null}
      {subHeading ? <SubHeading>{subHeading}</SubHeading> : null}
      {subHeading ? <SubSubHeading>{subSubHeading}</SubSubHeading> : null}
      {ctaLink ? (
        <a
          href={ctaLink}
          // target={ctaBehaviour ? null : "_blank"}
        >
          <CTA>{ctaText}</CTA>
        </a>
      ) : null}
    </Container>
  )
}

export default Panel
