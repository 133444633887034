import tw from "twin.macro"

export const Heading = tw.div`
w-3/4 leading-tight mb-3 sm:mb-4 md:mb-5 font-bold lg:mb-6 mx-auto px-2 text-center text-xl md:text-3xl
`
export const SubHeading = tw.div`
w-3/4 mx-auto px-2 text-center prose prose-lg sm:prose-xl 
`
export const SubSubHeading = tw.div`
w-3/4 mx-auto px-2 text-center text-xs text-gray-500 
`
